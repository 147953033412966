/*
 * @Author: jiameng15
 * @Date: 2021-07-09 09:38:43
 * @Last Modified by: jiameng15
 * @Last Modified time: 2024-12-11 17:33:20
 * 用户相关接口
 */

import request from "@/utils/request"; // 引入request,基于axios
import queryString from "querystring"; // post方法传参转型String

export function getDetail(data) {
  return request({
    url: "/course/detail",
    method: "get",
    params: data,
  });
}
export function getPublicDetail(data) {
  return request({
    url: "/public/course/detail",
    method: "get",
    params: data,
  });
}
export function getTeachers(data) {
  return request({
    url: "/public/course/lecturers",
    method: "get",
    params: data,
  });
}
export function getChapters(data) {
  return request({
    url: "/public/course/chapters",
    method: "get",
    params: data,
  });
}

export function getPage(data) {
  return request({
    url: "/course/comments",
    method: "get",
    params: data,
  });
}
// 课程购买
export function getPayInfo(data) {
  return request({
    url: "/course/order/pay",
    method: "post",
    data: queryString.stringify(data),
  });
}
// 评论新增
export function addComment(data) {
  return request({
    url: "/course/comment/add",
    method: "post",
    data: queryString.stringify(data),
  });
}
