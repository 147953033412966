<!-- 首页 -->
<template>
  <div class="container HomeDetail">
    <!-- <img src="@/assets/images/home/bg@2x (1).png" alt="" class="bg" /> -->
    <div class="logo" @touchmove.stop.prevent>
      <div class="back" @click="back()">
        <img src="@/assets/images/home/back@2x.png" alt />
      </div>
      <img :src="newData.meetingLogo" alt />
    </div>
    <div class="detail_wrap" ref="scrollWrap">
      <div class="video_wrap" @touchmove.stop.prevent>
        <div class="video_container ignore">
          <vue-aliplayer-v2
            ref="VueAliplayerV2"
            :options="options"
            v-if="options.playauth"
            @play="canICount"
          />
          <div class="need_fee" v-else-if="!options.playauth && options.cover">
            <img :src="options.cover" alt />
            <div class="need_fee_mask">
              <div class="fee_btn" @click="showPayPop">
                <img src="@/assets/images/home/detail/play_btn.png" alt />
                需要付费
              </div>
            </div>
          </div>
          <img :src="videoDetail.detailTopImg" alt v-else />
        </div>
      </div>
      <div class="info_wrap">
        <van-tabs
          v-model="activeName"
          class="custom_style custom_style_detail ignore"
          animated
          swipeable
          :before-change="onBeforeChange"
        >
          <van-tab
            :title="tab.title"
            :name="tab.activeName"
            v-for="tab in tabs"
            :key="tab.activeName"
          >
            <div class="detail_info_wrap" v-if="tab.activeName === '0'">
              <div class="video_title text_overflow_line2">
                {{ videoDetail.courseName }}
              </div>
              <div class="video_subtitle">
                <div class="sub_left">
                  <img src alt />
                  <!-- <div class="play_counts">
                  <img
                    src="@/assets/images/home/detail/counts_icon.png"
                    alt=""
                    class=""
                  />
                  {{ videoDetail.playCounts }}
                  </div>-->
                  已有{{ videoDetail.joinNum }}人加入本课程
                </div>
                <div class="sub_right">{{ videoDetail.publishDate }}</div>
              </div>
              <div class="teacher_info">
                <div
                  class="teacher_item"
                  v-for="item in teachers"
                  :key="item.id"
                >
                  <img class="teacher_header" :src="item.lecturerAvatar" alt />
                  <div class="teacher_info_content">
                    <div class="teacher_name text_overflow">
                      {{ item.lecturerName }}
                    </div>
                    <div class="teacher_title text_overflow_line2">
                      {{ item.lecturerTitle }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_info" v-html="videoDetail.courseDetail"></div>
            </div>
            <div class="chapters_info" v-if="tab.activeName === '1'">
              <div class="chapters_desc">
                <div>共{{ videoDetail.chapterCounts }}集</div>
                <div>已更新{{ videoDetail.updateNum }}集</div>
              </div>
              <div class="chapters_list" :class="{ sm: showBtn }">
                <div
                  class="chapters_item"
                  :class="{ active: chapter.isDown }"
                  v-for="(chapter, cIdx) in chapters"
                  :key="chapter.id"
                  @click.stop="toggleDown(cIdx)"
                >
                  <div class="chapter_header">
                    <!-- <div>{{cIdx<10?'0'+(cIdx+1):cIdx+1}} {{chapter.chapterCatName}}</div> -->
                    <div class="chapter_title">
                      {{ chapter.chapterCatName }}
                    </div>
                    <img
                      class="arrow_icon"
                      src="@/assets/images/home/detail/arrow.png"
                      alt
                    />
                  </div>
                  <div class="chapter_subtitle" v-if="chapter.chapterCatDesc">
                    {{ chapter.chapterCatDesc }}
                  </div>
                  <div
                    class="chapter_children"
                    :style="{ height: chapter.isDown ? 'auto' : '0px' }"
                  >
                    <!-- :style="{maxHeight:calcHeight(chapter.chapterList.length,chapter.isDown)}" -->
                    <div
                      class="chapter_child can_click"
                      v-for="(child, childIdx) in chapter.chapterList"
                      :key="child.id"
                      @click.stop="getAuth(child)"
                    >
                      <div class="golden_text_wrap">
                        <span class="golden_text">{{
                          childIdx &lt; 10 ? "0" + (childIdx + 1) : childIdx + 1
                        }}</span>
                        {{ child.chapterName }}
                      </div>
                      <div class="time_text">
                        {{
                          `${child.chapterTimes} | ${child.playCounts}人看过`
                        }}
                      </div>
                      <div class="free_tag" v-if="videoDetail.isHave === 0">
                        <img
                          src="@/assets/images/home/detail/fee.png"
                          alt
                          v-if="child.isFree === 1"
                        />
                        <img
                          src="@/assets/images/home/detail/free.png"
                          alt
                          v-if="child.isFree === 0"
                        />
                      </div>
                      <!-- <div class="free_tag" v-if="videoDetail.isHave===1">
                        <img src="@/assets/images/home/detail/free.png" alt />
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="course_comment" v-if="tab.activeName === '2'">
              <div class="chapters_desc">
                <div>全部评价（{{ pages.total }}）</div>
              </div>
              <van-list
                v-model="refreshLoading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="true"
                @load="onLoad"
                class="van-list"
              >
                <div
                  class="list_item"
                  v-for="item in list"
                  :key="item.id"
                  @click="toCourseDetails(item)"
                >
                  <div class="item_left">
                    <img :src="item.avatar" alt />
                  </div>
                  <div class="item_right">
                    <div class="user_info">
                      <div class="user_name">{{ item.nickname }}</div>
                      <div class="user_time">{{ item.createTime }}</div>
                    </div>
                    <div class="user_text">{{ item.theContent }}</div>
                  </div>
                </div>
              </van-list>
              <div class="add_comment" v-if="activeName === '2'">
                <van-field
                  class="add_box"
                  v-model="message"
                  autosize
                  rows="1"
                  label
                  type="textarea"
                  maxlength="50"
                  placeholder="写下您最真实的评价"
                  show-word-limit
                />
                <div class="btn_add">
                  <van-button
                    plain
                    type="primary"
                    class="btn"
                    @click="addComment"
                    >评价</van-button
                  >
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
        <div class="footer" v-if="hasToken && showBtn && activeName === '1'">
          <div class="footer_left">
            <div class="footer_left_top">
              <span>¥</span>
              {{ videoDetail.coursePrice | moneyFormat }}
            </div>
            <div class="footer_left_bottom">购买完成后即可观看所有章节</div>
          </div>
          <div class="btn_class">
            <van-button plain type="primary" class="btn" @click="showPayPop"
              >立即购买</van-button
            >
          </div>
        </div>
        <div class="footer" v-if="!hasToken && activeName === '1'">
          <div class="footer_left">
            <div class="footer_left_top">
              <span>¥</span>
              {{ videoDetail.coursePrice | moneyFormat }}
            </div>
            <div class="footer_left_bottom">购买完成后即可观看所有章节</div>
          </div>
          <div class="btn_class">
            <van-button plain type="primary" class="btn" @click="toLogin"
              >立即购买</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <van-dialog
      v-model="tellShow"
      :showConfirmButton="false"
      class="van-dialog2"
    >
      <div class="vant_title"></div>
      <img src="@/assets/images/home/about.png" alt class="vant_img" />
      <div class="vant_title2">
        <span>客服电话</span>
        <br />
        <span style="font-size: 20px">
          <a :href="'tel:' + newData.contactUs">
            {{ newData.contactUs }}
          </a>
        </span>
      </div>
      <van-button class="vant_btn" @click="tellShow = false">返回</van-button>
    </van-dialog>
    <van-popup v-model="showPop" round position="bottom">
      <div class="pay_wrap">
        <div class="pay_info">
          <div class="pay_title">购买课程</div>
          <div class="pay_fee">
            <span>¥</span>
            {{ videoDetail.coursePrice | moneyFormat }}
          </div>
          <div class="pay_tip_no_refund">
            目前课程购买完成后不支持退款，请您确认是否购买！
          </div>
          <div class="pay_wx_bar">
            <div class="pay_wx_left">
              <img src="@/assets/images/home/detail/wx.png" alt />
              微信支付
            </div>
            <img
              class="pay_wx_right"
              src="@/assets/images/home/detail/dui.png"
              alt
            />
          </div>
        </div>
        <div class="pay_bar">
          <div class="btn_class">
            <van-button plain type="primary" class="btn" @click="wxPay"
              >立即购买</van-button
            >
          </div>
          <!-- <div class="tip_text">开通既代表同意《聆课咨询支付协议》</div> -->
        </div>
      </div>
    </van-popup>
    <!-- 简介 -->
    <router-view class></router-view>
  </div>
</template>
<script>
import _ from "lodash";
import { getToken } from "@/utils/auth";
import { Dialog, GridItem, Toast } from "vant";
import {
  getDetail,
  getPublicDetail,
  getTeachers,
  getChapters,
  getPage,
  getPayInfo,
  addComment,
} from "@/api/course";
import { getMyInfo } from "@/api/login";
import {
  getStore,
  removeStore,
  setSessionStore,
  removeSessionStore,
} from "../../utils/mUtils";
import { getVideoAuth, postPlayCount } from "@/api/ali";

// import isEmpty from "@/components/Empty/building";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    // isEmpty,
    "van-dialog": Dialog.Component,
  },
  data() {
    return {
      hasToken: false,
      canCount: true,
      message: "",
      showPop: false,
      activeName: "0",
      options: {},
      teachers: [],
      chapters: [],
      videoDetail: {
        courseName: "",
      },
      showBtn: false,
      showDownQrCode: false,
      isLoading: false,
      finished: false,
      newData: {},
      theId: null,
      theChild: null,
      sid: null,
      tellShow: false,
      contentShow: false,
      tabs: [
        {
          title: "课程简介",
          activeName: "0",
          status: -1,
          list: [],
          refreshLoading: false,
          listLoading: false,
          finished: false,
          pages: {
            current: 0,
            size: 10,
            pages: 1,
            total: 0,
          },
        },
        {
          title: "课程目录",
          activeName: "1",
          status: 0,
          list: [],
          refreshLoading: false,
          listLoading: false,
          finished: false,
          pages: {
            current: 0,
            size: 10,
            pages: 1,
            total: 0,
          },
        },
        {
          title: "课程评价",
          activeName: "2",
          status: 0,
          list: [],
          refreshLoading: false,
          listLoading: false,
          finished: false,
          pages: {
            current: 0,
            size: 10,
            pages: 1,
            total: 0,
          },
        },
      ],
      refreshLoading: false,
      list: [],
      pages: {
        current: 0,
        size: 10,
        total: 0,
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    activeName(newValue, oldValue) {
      console.log("activeName", newValue, oldValue);
      this.activeName = newValue;
      // this.getPages();
    },
    "userInfo.isRead"(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue === 0) {
        this.showPopup();
      } else {
        // this.checkSign();
      }
    },
  },
  // 方法集合
  methods: {
    async onBeforeChange(index) {
      console.log(index);
      setSessionStore("REDIRECT_INFO", {
        path: "/home/detail",
        query: { id: this.theId, active: index },
      });
      if (index === "2") {
        const res = await this.checkToken();
        // this.checkToken().then((res) => {
        console.log("看看返回结果true还是false", res);
        if (res) {
          return true;
        } else {
          removeStore("token");
          this.$router.push({
            path: "/welcome",
          });
          // });
          return false;
        }
        // });
      } else {
        return true;
      }
    },
    resetVideo() {
      console.log(this.options);
      console.log(this.theChild);
      if (this.options.vid && this.theChild.isFree) {
        this.getAuth(this.theChild, true);
      }
    },
    async addComment() {
      if (
        this.message === "" ||
        this.message.replace(/(^\s*)|(\s*$)/g, "") === ""
      ) {
        Toast("请输入评价");
        return false;
      }
      const res = await addComment({
        id: this.theId,
        theContent: this.message,
      });
      Toast({ message: "提交成功，请等待平台审核后展示", duration: 4000 });
      this.message = "";
      this.onRefresh();
    },
    showPayPop() {
      this.showPop = true;
    },
    async getAuth(child, fee) {
      this.theChild = child;
      let { id, videoId, isFree } = child;
      if (fee) {
        isFree = 0;
      }
      const res = await getVideoAuth({ chapterId: id, videoId });
      console.log(res);
      if (res.code === -401) {
        this.$router.push({
          path: "/welcome",
        });
        return false;
      }
      if (this.videoDetail.isHave === 0 && isFree === 1) {
        this.options = {
          width: "100%",
          height: "100%",
          vid: res.data.videoMeta.videoId,
          cover: res.data.videoMeta.coverURL,
        };
        console.log(this.options);
        this.$refs.scrollWrap.scrollTop = 0;
        return false;
      }
      this.options = {
        width: "100%",
        height: "100%",
        vid: res.data.videoMeta.videoId,
        playauth: res.data.playAuth,
        autoplay: false,
        isLive: false,
        cover: res.data.videoMeta.coverURL,
        preventRecord: true,
        qualitySort: "desc",
        controlBarVisibility: "click",
        showBarTime: 5000,
        // skinLayout:false,
        // skinLayoutIgnore:[
        //   'fullscreen', 'volume',
        // ]
      };
      // 回到顶部
      console.log(this.$refs.scrollWrap.scrollTop);
      this.$refs.scrollWrap.scrollTop = 0;
      this.canCount = true;
    },

    canICount() {
      if (this.canCount) {
        this.postPlayCount();
      }
    },
    async postPlayCount() {
      const res = await postPlayCount({ chapterId: this.theChild.id });
      this.canCount = false;
    },
    async checkToken() {
      const res = await getMyInfo();
      console.log("校验token是否有效", res);
      //校验token是否有效
      if (res.code === 1) {
        return true;
      } else {
        return false;
      }
    },

    // 获取列表
    async getDetail() {
      const res = await getDetail({ id: this.theId });
      if (res.data) {
        this.videoDetail = res.data;
        console.log(this.videoDetail.isHave);
        if (this.videoDetail.isHave === 0) {
          this.toggleBtn(true);
        } else {
          this.toggleBtn(false);
        }
        // this.initWXJSSDK({
        //   title: res.data.courseName,
        //   desc: res.data.wxShareDesc,
        //   link: this.$BaseUrl + "home/detail" + location.search, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png", // 分享图标
        // });
        this.$wx.ready(() => {
          this.$wx.updateAppMessageShareData({
            title: res.data.courseName,
            desc: res.data.wxShareDesc,
            link: this.$BaseUrl + "home/detail" + location.search, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png", // 分享图标
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
          this.$wx.updateTimelineShareData({
            title: res.data.courseName,
            desc: res.data.wxShareDesc,
            link: this.$BaseUrl + "home/detail" + location.search, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png", // 分享图标
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
        });
        // this.newData.signStatus = 1
        // this.newData.isSign = 1
      }
    },
    // 获取列表
    async getPublicDetail() {
      const res = await getPublicDetail({ id: this.theId });
      if (res.data) {
        this.videoDetail = res.data;

        this.$wx.ready(() => {
          this.$wx.updateAppMessageShareData({
            title: res.data.courseName,
            desc: res.data.wxShareDesc,
            link: this.$BaseUrl + "home/detail" + location.search, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png", // 分享图标
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
          this.$wx.updateTimelineShareData({
            title: res.data.courseName,
            desc: res.data.wxShareDesc,
            link: this.$BaseUrl + "home/detail" + location.search, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png", // 分享图标
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
        });
      }
    },
    shareFunc() {},
    async getTeachers() {
      this.teachers = [];
      const res = await getTeachers({ id: this.theId });
      // for (let i = 0; i < res.data.length; i += 2) {
      //   if (res.data[i + 1]) {
      //     this.teachers.push([res.data[i], res.data[i + 1]]);
      //   } else {
      //     this.teachers.push([res.data[i]]);
      //   }
      // }
      this.teachers = res.data;
      // console.log(this.teachers);
    },
    async getChapters() {
      this.chapters = [];
      const res = await getChapters({ id: this.theId });
      res.data.map((item) => {
        item.isDown = true;
        this.chapters.push(item);
      });
    },
    toggleDown(cIdx) {
      console.log(cIdx);
      console.log(this.chapters[cIdx].isDown);
      this.chapters[cIdx].isDown = !this.chapters[cIdx].isDown;
      console.log(this.chapters[cIdx].isDown);
      this.$set(this.chapters, cIdx, this.chapters[cIdx]);
    },
    back() {
      removeSessionStore("REDIRECT_INFO");
      if (location.href.includes("home")) {
        this.$router.push({
          path: "/home",
        });
      } else {
        this.$router.go(-1); //返回上一层
      }
    },
    async checkQrFunc() {
      const isSign = await this.isSignCheck(this.theId);
      console.log(isSign);
      if (isSign) {
        // 跳转我的详情
        this.$router.push({
          path: "/home/mymeeting/myHomeDetail",
          query: { id: this.theId, from: "qrcode" },
        });
      }
    },
    toggleBtn(status) {
      console.log("调用toggleBtn", status);
      this.showBtn = status;
    },
    // 获取列表
    async getPages() {
      let postData = {
        id: this.theId,
        current: this.pages.current,
        size: this.pages.size,
      };
      const res = await getPage(postData);
      const {
        data,
        data: { records },
      } = res;
      if (this.pages.current === 1) {
        this.list = records;
      } else {
        this.list = this.list.concat(records);
      }
      this.pages.total = data.total;
      if (data.total <= this.pages.current * 10) {
        this.finished = true;
      }
      this.refreshLoading = false;
    },
    onRefresh() {
      this.pages.current = 1;
      this.getPages();
      setTimeout(() => {
        this.refreshLoading = false;
      }, 500);
    },
    onLoad() {
      this.pages.current++;
      this.finished = false;
      this.getPages();
    },
    async getWxPerPayInfo() {
      // 获取支付信息
      const res = await getPayInfo({ id: this.theId });

      const { data } = res;
      this.perPayInfo = {
        appId: data.appId,
        timeStamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.packageStr,
        signType: data.signType,
        paySign: data.paySign,
      };
      console.log(this.perPayInfo);
      this.onBridgeReady();
    },

    wxPay() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.getWxPerPayInfo();
      }
    },
    onBridgeReady() {
      let _this = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", this.perPayInfo, function(
        res
      ) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          _this.$EventBus.$emit("showDialog", "paySuccess");
          _this.initDetail();
          _this.resetVideo();
          _this.showPop = false;
        } else {
          _this.$EventBus.$emit("showDialog", "payFail");
        }
      });
    },
    calcHeight(length, isDown) {
      if (this.activeName == "1") {
        let maxHeight = 0;
        let el = document.querySelector(".chapter_child");
        if (isDown) {
          let height = window.getComputedStyle(el).height.split("px")[0] * 1;
          let marginTop =
            window.getComputedStyle(el).marginTop.split("px")[0] * 1;

          maxHeight = length * (height + marginTop);
        }
        return maxHeight + "px";
      }
    },
    initDetailWithOutToken() {
      this.hasToken = false;
      this.getPublicDetail();
      this.getTeachers();
      this.getChapters();
    },
    async initDetail() {
      console.log("看看token", await this.checkToken());
      if (await this.checkToken()) {
        this.hasToken = true;
        this.getDetail();
        this.getTeachers();
        this.getChapters();
      } else {
        this.initDetailWithOutToken();
      }
    },
    toLogin() {
      this.$router.push({
        path: "/welcome",
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route) {
      console.log("this.route", this.$route);
      if (this.$route.path.includes("lesson")) {
        this.activeName = "1";
      }
    }
    // this.initWXJSSDK()
    // this.initWXJSSDK({
    //   title:this.$route.query.name||'课程详情',
    //   desc:'独家孙子兵法商业实践',
    //   link:location.href,
    //   imgUrl:'http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png',
    // })
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query.id || this.$route.query.mid) {
      this.theId = this.$route.query.id || this.$route.query.mid;
      if (this.$route.query.active) {
        this.activeName = this.$route.query.active;
      }
      this.initDetail();
    }

    this.$nextTick(() => {
      // this.iosScroll('detail_info_wrap')
    });
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variable.scss";
$ListHeight: calc(100vh - 120px);
.container {
  color: #333333;
  height: $ListHeight;
  background-color: #000;

  /* margin-top: 112px; */
  .logo {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    img {
      height: 112px;
      z-index: 1;
    }
    .back {
      position: absolute;
      left: 0;
      z-index: 99;
      padding: 20px;
      img {
        height: 45px;
        font-size: 32px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
      }
      span:last-child {
        margin-left: 20px;
      }
    }
  }
  .statusImg {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 22;
    img {
      width: 144px;
      height: 42px;
    }
  }
  .title {
    font-size: 45px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: 22;
    padding: 0 20px;
  }
  .footer {
    padding: 30px;
    width: 750px;
    height: 185px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 2px 0px 37px 0px rgba(0, 0, 0, 0.08);
    .footer_left {
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .footer_left_top {
        font-family: Roboto, Roboto;
        font-weight: 700;
        font-size: 40px;
        color: #1994f4;
        line-height: 40px;
        span {
          font-size: 20px;
        }
      }
      .footer_left_bottom {
        font-weight: 400;
        font-size: 20px;
        color: #999999;
        line-height: 23px;
      }
    }
    .btn_class {
      width: 232px;
      /* height: 90px; */
      position: relative;
      bottom: 0;
      display: flex;
      .btn {
        // background-color: $baseColor;
        background: #1994f4;
        border: none;
        border-radius: 89px 89px 89px 89px;
        width: 100%;
        height: 100px;
      }
      .van-button--plain.van-button--primary {
        color: #ffffff;
        font-size: 28px;
        height: 87px;
        font-weight: 700;
      }
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* z-index: 99; */
  }
}
.pay_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 646px;
  background: #f7f7f7;
  .pay_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px 40px 30px;
    width: 100vw;
    height: 432px;
  }
  .pay_title {
    font-weight: 500;
    font-size: 28px;
    color: #333333;
    line-height: 36px;
  }
  .pay_fee {
    margin-top: 18px;
    font-family: Roboto, Roboto;
    font-weight: 700;
    font-size: 40px;
    color: #333;
    line-height: 40px;
    span {
      font-size: 20px;
    }
  }
  .pay_tip_no_refund {
    margin-top: 22px;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    line-height: 31px;
  }
  .pay_wx_bar {
    margin-top: 40px;
    padding: 33px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 690px;
    height: 110px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    .pay_wx_left {
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
        width: 47px;
        height: 43px;
      }
    }
    .pay_wx_right {
      width: 36px;
      height: 36px;
    }
  }
  .pay_bar {
    padding: 30px;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 214px;
    box-shadow: 2px 0px 37px 0px rgba(0, 0, 0, 0.08);
    .btn_class {
      position: relative;
      bottom: 0;
      display: flex;
      .btn {
        // background-color: $baseColor;
        background: #1994f4;
        border: none;
        border-radius: 89px 89px 89px 89px;
        width: 100%;
        height: 100px;
      }
      .van-button--plain.van-button--primary {
        color: #ffffff;
        font-size: 28px;
        width: 690px;
        height: 87px;
        font-weight: 700;
      }
    }
    .tip_text {
      margin-top: 16px;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      color: #b4b4b4;
      line-height: 26px;
    }
  }
}
.detail_wrap {
  /* margin-top: 120px; */
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  /* border-radius: 30px 30px 0px 0px; */
  overflow-y: scroll;

  .video_wrap {
    /* height: 768px; */
    width: 750px;
    background: #ffffff;
    /* padding: 40px 30px; */
    .video_container {
      width: 100%;
      height: 420px;
      /* border-radius: 10px; */
      background-color: #f7f7f7;
      overflow: hidden;
      .need_fee {
        position: relative;
        width: 100%;
        height: 100%;
        .need_fee_mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          .fee_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 202px;
            height: 64px;
            background: linear-gradient(90deg, #ffeccd 0%, #d5bb99 100%);
            border-radius: 105px 105px 105px 105px;
            font-weight: 700;
            font-size: 24px;
            color: #96652d;
            line-height: 28px;
            img {
              width: 38px;
              height: 38px;
              margin-right: 10px;
            }
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .info_wrap {
    height: calc(100vh - 420px);
    background-color: #fff;
    box-sizing: border-box;
    /* margin-top: 10px; */
    .detail_info_wrap {
      padding: 0 30px;
      height: 100%;
      overflow-y: scroll;
      .video_title {
        margin-top: 10px;
        /* height: 84px; */
        font-weight: 600;
        font-size: 36px;
        color: #333333;
        line-height: 42px;
      }
      .video_subtitle {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 28px;
        .sub_left {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .play_counts {
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          color: #d5b87f;
          line-height: 28px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
      .detail_info {
        font-weight: 400;
        font-size: 24px;
        color: #666666;
        line-height: 48px;
        word-break: break-all;
        ::v-deep {
          img {
            width: 100% !important;
            height: auto !important;
            border-radius: 10px;
          }
        }
      }
    }

    .chapters_info {
      height: 100%;
      padding: 0 30px;
      .chapters_desc {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 28px;
      }
      .chapters_list {
        overflow-y: scroll;
        height: calc(100% - 34px);
        &.sm {
          height: calc(100% - 215px);
        }
        .chapters_item {
          /* background:#123abc; */
          width: 100%;
          /* min-height: 160px; */
          border-bottom: 2px solid #f7f7f7;
          padding: 24px 0;
          &.active {
            min-height: auto;
            .arrow_icon {
              transform: rotate(180deg);
            }
            .chapter_children {
              /* display: block; */
              /* max-height: 500px; */
            }
          }
          .chapter_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .chapter_title {
              font-weight: 500;
              font-size: 28px;
              color: #333333;
              line-height: 33px;
            }
            img {
              height: 13px;
              width: 21px;
              transition: all 0.5s;
            }
          }
          .chapter_subtitle {
            margin-top: 10px;
            width: 690px;
            min-height: 60px;
            font-weight: 400;
            font-size: 20px;
            color: #999999;
            line-height: 30px;
          }
          .chapter_children {
            transition: all 0.8s;
            /* display: none; */
            /* max-height: 0; */
            overflow: hidden;
            .chapter_child {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              width: 690px;
              height: 130px;
              background: #f7f7f7;
              border-radius: 10px 10px 10px 10px;
              margin-top: 14px;
              padding: 30px 60px 30px 30px;
              font-weight: 400;
              font-size: 24px;
              color: #333333;
              line-height: 30px;
              .golden_text_wrap {
                /* display: flex;
                align-items: baseline; */
              }
              .golden_text {
                color: #ffc60a;
                font-size: 30px;
                line-height: 30px;
                font-weight: 700;
              }
              .time_text {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 24px;
                color: #999999;
                line-height: 28px;
              }
              .free_tag {
                position: absolute;
                top: 0;
                right: 0;
                width: 56px;
                height: 30px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .course_comment {
      position: relative;
      height: 100%;
      /* padding: 0 30px; */
      display: flex;
      flex-direction: column;
      .chapters_desc {
        padding: 0 30px;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 28px;
        color: #333333;
        line-height: 33px;
      }
      .van-list {
        padding: 0 30px;
        /* height: calc(100% - 100px); */
        flex: 1;
        overflow-y: scroll;
        padding-top: 1px;
        .list_item {
          display: flex;
          justify-content: space-between;
          padding-top: 8px;
          margin-bottom: 30px;
          width: 100%;
          border-radius: 20px;
          background: #fff;
          .item_left {
            width: 54px;
            margin-right: 20px;
            img {
              width: 54px;
              height: 54px;
              background: linear-gradient(180deg, #e3e3e3 0%, #c1c0c0 100%);
              border-radius: 61px 61px 61px 61px;
            }
          }
          .item_right {
            width: 100%;
            .user_info {
              display: flex;
              justify-content: space-between;
              .user_name {
                font-weight: 400;
                font-size: 28px;
                color: #333333;
                line-height: 33px;
              }
              .user_time {
                font-weight: 400;
                font-size: 24px;
                color: #999999;
                line-height: 28px;
              }
            }

            .user_text {
              margin-top: 6px;
              padding-bottom: 24px;
              border-bottom: 2px solid #f7f7f7;
              font-weight: 400;
              font-size: 24px;
              color: #666666;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
  .teacher_info {
    width: 690px;
    margin-top: 24px;
    .teacher_item {
      margin-bottom: 20px;
      width: 100%;
      height: 120px;
      padding: 20px;
      display: flex;
      border-radius: 10px 10px 10px 10px;
      border: 2px solid #f7f7f7;
      .teacher_header {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .teacher_info_content {
        width: 100%;
        margin-left: 24px;
        display: flex;
        align-items: center;
        .teacher_name {
          font-weight: 400;
          font-size: 28px;
          color: #333333;
          margin-right: 20px;
          line-height: 80px;
        }
        .teacher_title {
          font-weight: 400;
          font-size: 24px;
          color: #999999;
          line-height: 80px;
        }
      }
    }
  }
}
.add_comment {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* position: fixed;
  left: 0;
  right: 0;
  bottom: 0; */
  width: 750px;
  height: auto;
  background: #ffffff;
  box-shadow: 2px 0px 37px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 0px 0px;
  padding: 20px 30px;
  .add_box {
    width: 560px;
    min-height: 67px;
    background: #faf9f9;
    border-radius: 16px;
  }
  .btn_add {
    width: 120px;
    /* height: 90px; */
    position: relative;
    bottom: 0;
    display: flex;
    .btn {
      // background-color: $baseColor;
      background: #1994f4;
      border: none;
      border-radius: 89px 89px 89px 89px;
      width: 100%;
      height: 100px;
    }
    .van-button--plain.van-button--primary {
      color: #ffffff;
      font-size: 28px;
      height: 87px;
      font-weight: 700;
    }
  }
}
</style>
<style lang="scss">
.HomeDetail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  .van-tabs__nav--line.van-tabs__nav--complete {
    /* background: transparent !important; */
    /* margin: 0 40px; */
    /* padding: 0 30px; */
    /* border-radius: 8px; */
  }

  .van-tabs.custom_style .van-tab {
    /* width: 180px; */
    /* height: 42px; */
    /* flex: none;
    font-size: 30px;
    color: #999999;
    line-height: 35px; */
    /* display: flex; */
    /* justify-content: start; */
    /* padding: 0; */
  }

  /* .van-tabs.custom_style .van-tab {
    background: #8a1520 !important;
    padding: 12px;
  } */
  .van-tabs.custom_style .van-tabs__line {
    background: #ffc60a;
    /* display: none; */
  }
  .van-tabs.custom_style.ignore .van-tabs__line {
    bottom: 22px;
  }

  .van-tabs.custom_style .van-tab--active {
    background: transparent !important;
    color: #333;
    /* width: 60px;
    height: 62px; */
    /* margin: 12px 0; */
    border-radius: 6px;
    .van-tab__text {
      z-index: 10;
    }
    /* &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 50%;
      margin-bottom: -16px;
      width: 155px;
      height: 16px;
      background: linear-gradient(
        -90deg,
        rgba(255, 236, 205, 0) 0%,
        #d5bb99 100%
      );
      border-radius: 0px 0px 0px 0px;
    } */
  }

  .van-pull-refresh {
    margin-top: 112px;
    overflow-y: scroll;
  }
  height: calc(100vh) !important;
  .order_list {
    /* margin: 140px 0; */
    /* height: calc(100vh - 286px); */
    overflow: scroll;
  }
  .van-dialog2 {
    width: 451px;
    height: 436px;
    border-radius: 24px;
    .vant_title {
      font-size: 32px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #181818;
      line-height: 46px;
      padding: 80px 50px;
    }
    .vant_title2 {
      font-size: 26px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #181818;
      margin: 0 40px;
      span {
        line-height: 60px;
      }
    }

    .vant_img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
    }
    .vant_btn {
      width: 90%;
      height: 65px;
      background: linear-gradient(
        180deg,
        #ffee7d 0%,
        #e49f54 100%,
        #e49f54 100%
      );
      border-radius: 38px;
      font-size: 26px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #f9f3f3;
      line-height: 65px;
      letter-spacing: 2px;
      text-align: center;
      margin: 50px 0 0 20px;
    }
  }
  .van-dialog3 {
    width: 488px;
    height: 729px;
    border-radius: 24px;
    padding: 30px;
    .vant_title {
      font-size: 32px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #181818;
      line-height: 46px;
    }
    .vant_title2 {
      font-size: 26px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #181818;
      overflow-y: scroll;
      height: 540px;
      margin: 100px 0px;
      padding-bottom: 80px;
      span {
        line-height: 40px;
      }
    }

    .vant_img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
    }
    .vant_btn {
      width: 90%;
      height: 65px;
      background: linear-gradient(
        180deg,
        #ffee7d 0%,
        #e49f54 100%,
        #e49f54 100%
      );
      border-radius: 38px;
      font-size: 26px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #f9f3f3;
      line-height: 65px;
      letter-spacing: 2px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 24px;
    }
  }
  .van-dialog4 {
    width: 488px;
    height: 578px;
    border-radius: 24px;
    padding: 30px;
    .vant_title {
      font-size: 32px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #181818;
      line-height: 46px;
      margin-bottom: 100px;
    }
    .vant_title2 {
      overflow: hidden;
      margin: 20px 0;
      div:first-child {
        float: left;
        img {
          width: 30px;
          display: inline-block;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 4px;
          font-size: 24px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          text-align: right;
        }
      }
      div:last-child {
        float: right;
        font-size: 28px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        text-align: right;
        width: 260px;
      }
    }

    .vant_img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
    }
    .vant_btn {
      width: 90%;
      height: 65px;
      background: linear-gradient(
        180deg,
        #ffee7d 0%,
        #e49f54 100%,
        #e49f54 100%
      );
      border-radius: 38px;
      font-size: 26px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #f9f3f3;
      line-height: 65px;
      letter-spacing: 2px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }
}
</style>
